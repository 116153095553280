import { Component, Input } from '@angular/core';
import { PdfService } from '../../services/pdf.service';
import { Observable } from 'rxjs';
import { HeaderLogo } from '../../../core/models/models';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'state-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent {
  @Input() logoHref: string;
  @Input() logos: HeaderLogo = {
    phoneLogoHref: '../../../assets/img/phone-ic.png',
    mailLogoHref: '../../../assets/img/mail-ic.png',
    saveLogoHref: '../../../assets/img/save-ic.png'
  };

  @Input() widthLogo?: number = 224;
  @Input() heightLogo?: number = 41;

  @Input() mailLink = 'crm@rsb.ru';
  @Input() cardtype$: Observable<string>;
  @Input() phone: Observable<string>;

  constructor(public data: DataService, public pdfService: PdfService) {}
}

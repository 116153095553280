import { Component } from '@angular/core';
import { InitService } from '../services/init.service';
import { StoreService } from '../services/store.service';
import { DataService } from "../services/data.service";

@Component({
  selector: 'state-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.less']
})
export class PagesComponent {
  constructor(public store: StoreService, private initService: InitService, public data: DataService) {
    // this.initService.load();
  }
}

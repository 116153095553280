import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'state-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements AfterViewInit {
  @Input() public hideBarcode!: boolean;
  @ViewChild('footerBlock', { static: true }) footerBlock: ElementRef;
  @ViewChild('footerCopyright', { static: true }) footerCopyright: ElementRef;

  constructor(public data: DataService, private r2: Renderer2) {}

  initCookie() {

    const block = this.r2.createElement('div');
    this.r2.setAttribute(block,'id','cookie-block');
    this.r2.setAttribute(block,'data-style', "font-size:12px;font-family:'LatoMedium',sans-serif;color:#333;");
    this.r2.appendChild(this.footerBlock.nativeElement, block);

    const script = this.r2.createElement('script');
    this.r2.setProperty(script,'src', 'https://rsb.ru/upload/external/js/cookie-info.js');
    this.r2.setAttribute(script,'defer', '');
    this.r2.appendChild(document.body, script);
  }

  ngAfterViewInit(): void {
    this.initCookie();
  }
}
